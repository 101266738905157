<template>
  <div class="card card--default">
    <div class="card__header">
      <div class="card__media media ratio">
        <div class="media__wrapper">
          <img
            :src="post.featured_image"
            class="img-fit"
            :alt="post.title"
            v-if="post.featured_image"
          />
        </div>
      </div>
    </div>

    <div class="card__body">
      <div class="card__label">
        <span>
          {{ post.date }}
        </span>
      </div>

      <div class="card__title">
        <a
          :href="post.permalink"
          class="stretched-link h5"
          :title="post.title"
          target="_blank"
        >
          {{ post.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object
    }
  },
  created() {
    // console.log(this.post);
  }
};
</script>

<style lang="css" scoped></style>
