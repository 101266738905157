<template>
  <div class="card card--default">
    <div class="card__header">
      <span
        v-t="'events.past-event'"
        class="card__header-label card__header-label--danger"
        v-if="event.past == 1"
      >
        Past event
      </span>
      <div class="card__media media ratio">
        <div class="media__wrapper">
          <img
            :src="event.featured_image"
            class="img-fit"
            :alt="event.title"
            v-if="event.featured_image"
          />
        </div>
      </div>
    </div>

    <div class="card__body">
      <div class="card__label" v-if="event.formatted_event_date">
        <span>
          {{ event.formatted_event_date }}
        </span>
      </div>

      <div class="card__title">
        <a
          :href="event.permalink"
          class="stretched-link h5"
          :title="event.title"
          target="_blank"
        >
          {{ event.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: {
      type: Object
    }
  }
};
</script>

<style lang="css" scoped></style>
