<template>
  <template v-if="banners.data && !isFetchingBanners">
    <section
      class="section banners banners--colored bg-light"
      v-if="
        Object.keys(banners.data.homepage).length > 0 &&
          banners.data.homepage[0].title != null
      "
    >
      <div class="container">
        <div class="row row--col-margin-small">
          <div
            class="col-lg-6"
            v-for="(banner, i) in banners.data.homepage"
            :key="i"
            :banner="banner"
          >
            <div class="banner box">
              <a
                :href="banner.permalink"
                class="banner__body box__body d-flex align-items-center"
              >
                <div class="banner__media media media--clipped ratio">
                  <div class="media__wrapper">
                    <!-- <img src="../../assets/images/corporate/corporate-7.jpg" alt="" class="media__src img-fit" loading="lazy"> -->
                    <img
                      :src="banner.thumbnail"
                      class="media__src img-fit"
                      :alt="banner.title"
                      v-if="banner.thumbnail"
                    />
                  </div>
                </div>

                <div class="banner__content">
                  <!-- <div
          class="banner__subtitle text-overline mb-2"
          v-if="banner.subtitle && banner.subtitle.length > 0"
        >
          {{ banner.subtitle }}
        </div> -->
                  <div class="banner__title h5">
                    <strong>
                      {{ banner.title }}
                    </strong>
                  </div>
                </div>
                <span class="banner__action ml-auto">
                  <span class="btn btn-sm btn-secondary btn-icon-only">
                    <i class="icon icon-keyboard_arrow_right"></i>
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>

  <section v-if="isFetchingBanners">
    <div class="container">
       <ContentLoader
        viewBox="0 0 690 700"
        style="width: 100%;"
        class="d-block d-lg-none"
      >
        <rect x="0" y="0" width="690" height="160" />
        <rect x="0" y="176" width="690" height="160" />
        >
        <rect x="0" y="352" width="690" height="160" />
        <rect x="0" y="528" width="690" height="160" />
      </ContentLoader>

      <ContentLoader
        viewBox="0 0 1580 470"
        style="width: 100%;"
        class="d-none d-lg-block"
      >
        <rect x="0" y="45" width="780" height="185" />
        <rect x="805" y="45" width="780" height="185" />
        >
        <rect x="0" y="245" width="780" height="185" />
        <rect x="805" y="245" width="780" height="185" />
      </ContentLoader>
    </div>
  </section>

  <template v-else-if="banners.data && !isFetchingBanners">
    <Categories></Categories>
  </template>


  <Events
    v-else-if="events.data && events.data.length && !isFetchingEvents"
    :events="events.data"
  >
  </Events>


  <section v-else-if="isFetchingEvents" class="section">
    <div class="container">
       <ContentLoader
          viewBox="0 0 1580 1100"
          style="width: 100%;"
          class="d-block d-md-none"
        >
          <rect x="550" y="0" width="420" height="60" />

          <rect x="0" y="80" width="507" height="304" />
          <rect x="530" y="200" width="907" height="100" />

          <rect x="0" y="405" width="507" height="304" />
          <rect x="530" y="525" width="907" height="100" />

          <rect x="0" y="730" width="507" height="304" />
          <rect x="530" y="850" width="907" height="100" />
      </ContentLoader>

      <ContentLoader
        viewBox="0 0 1580 480"
        style="width: 100%;"
        class="d-none d-md-block"
      >
        <rect x="0" y="0" width="420" height="33px" />

        <rect x="0" y="50" width="507" height="336" />
        <rect x="538" y="50" width="507" height="336" />
        <rect x="1073" y="50" width="507" height="336" />
      </ContentLoader>
    </div>
  </section>

  <Posts
    v-if="posts.data && posts.data.length && !isFetchingArticles"
    :posts="posts.data"
  >
  </Posts>

  <section v-else-if="isFetchingArticles" class="section">
    <div class="container">
       <ContentLoader
          viewBox="0 0 1580 1100"
          style="width: 100%;"
          class="d-block d-md-none"
        >
          <rect x="550" y="0" width="420" height="60" />

          <rect x="0" y="80" width="507" height="304" />
          <rect x="530" y="200" width="907" height="100" />

          <rect x="0" y="405" width="507" height="304" />
          <rect x="530" y="525" width="907" height="100" />

          <rect x="0" y="730" width="507" height="304" />
          <rect x="530" y="850" width="907" height="100" />
        </ContentLoader>

      <ContentLoader
        viewBox="0 0 1580 480"
        style="width: 100%;"
        class="d-none d-md-block"
      >
        <rect x="0" y="0" width="420" height="33px" />

        <rect x="0" y="50" width="507" height="336" />
        <rect x="538" y="50" width="507" height="336" />
        <rect x="1073" y="50" width="507" height="336" />
      </ContentLoader>
    </div>
  </section>


</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Categories from "../components/Categories/Section.vue";
// import Banner from "../components/Banners/Banner.vue";
// import Brands from "../components/Brands/Section.vue";
import Events from "../components/Events/Section.vue";
import Posts from "../components/Posts/Section.vue";
// import Order from '../components/Orders/Order.vue';
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    // BannerSection,
    Categories,
    // CTA1,
    // Brands,
    // Banner,
    Events,
    Posts,
    ContentLoader
    // Order
    // EventsLatest
  },
  data: () => ({
    isFetchingArticles: true,
    isFetchingEvents: true,
    isFetchingBanners: true,
    brands: [
      {
        info: {
          title: "VETUS Brands",
          desc:
            "Sed viverra, turpis at ornare convallis, arcu quam placerat ipsum, id mollis justo lectus vel arcu."
        },
        data: [
          {
            title: "VETUS",
            desc: "Lorem ipsum",
            image: require("./../assets/images/logo/vetus.svg"),
            buttons: [
              {
                title: "More about VETUS",
                url: "#",
                target: "_self"
              }
            ]
          },
          {
            title: "Marex",
            desc: "Lorem",
            image: require("./../assets/images/logo/marex.svg"),
            buttons: [
              {
                title: "More about Marex",
                url: "#",
                target: "_self"
              }
            ]
          }
        ]
      }
    ],
    events: [],
    posts: [],
    banners: []
  }),
  created() {
    let locale = "en";
    if (this.$i18n.locale != undefined) {
      locale = this.$i18n.locale;
    }

    let that = this;

    axios
      .get(
        `${process.env.VUE_APP_CMS_ENDPOINT_URL}/wp-json/vt/v1/articles/?lang=${locale}`
      )
      .then(function(response) {
        that.posts = response;
        if (response.data) {
          that.isFetchingArticles = false;
        }
      })
      .catch(error => console.log(error));

    axios
      .get(
        `${process.env.VUE_APP_CMS_ENDPOINT_URL}/wp-json/vt/v1/webshop_banners/?lang=${locale}`
      )
      .then(function(response) {
        that.banners = response;
        if (response.data) {
          that.isFetchingBanners = false;
        }
      })
      .catch(error => console.log(error));

    axios
      .get(
        `${process.env.VUE_APP_CMS_ENDPOINT_URL}/wp-json/vt/v1/events/?lang=${locale}`
      )
      .then(function(response) {
        that.events = response;
        if (response.data) {
          that.isFetchingEvents = false;
        }
      })
      .catch(error => console.log(error));
  }
};
</script>

<style lang="css" scoped></style>
