<template>
  <section class="section cards cards--cat-prod">
    <div class="section__header cards__header text-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 offset-lg-3">

            <h3
              class="section__title cards__title h2"
              v-t="'home.categories.title'"
            >
              VETUS Systems
            </h3>

            <div class="section__intro text-lg">
              <p v-t="'home.categories.content'">
                Sed viverra, turpis at ornare convallis, arcu quam placerat
                ipsum, id mollis justo lectus vel arcu.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CategoryLoader v-if="isFetchingCategories" :categories="18" :classes="'col-6 col-md-4 col-lg-3 col-xl-2'"></CategoryLoader>

    <div class="section__body cards__body">
      <div class="container">
        <div class="row row--col-margin-medium">
          <div
            class="col-6 col-md-4 col-lg-3 col-xl-2"
            v-for="(category, i) in categories"
            :key="i"
          >
          <Category :category="category" :index="category.url_path" :level=1> </Category>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Category from "./Category.vue";
import { mapGetters, mapActions } from "vuex";
import CategoryLoader from "./CategoryLoader.vue";
export default {
  components: {
    Category,
    CategoryLoader
},
  props: {
    categorySlug: {
      type: String
    },
    subCategorySlug: {
      type: String
    }
  },
  data() {
    return {
      // categories: []
    };
  },
  created() {
    this.fetchCategories();
  },
  computed: {
    ...mapGetters(["categories", "isFetchingCategories"]),
  },
    watch: {
    isFetchingCategories: function() {
      if (this.category) {
        // Title
        if (this.category.meta_title) {
          document.title = this.category.meta_title + " - VETUS";
        } else {
          document.title = this.category.name + " - VETUS";
        }

        // Meta description
        if (this.category.meta_description) {
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", this.category.meta_description);
        } else {
          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", "");
        }
      }
    }
  },
  methods: {
    // Make specified vuex actions available
    ...mapActions(["fetchCategories"]),
  },
};
</script>

<style lang="css" scoped></style>
