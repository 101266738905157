<template>
  <section class="section cards cards--default">
    <div class="section__header mb-4 cards__header">
      <div
        class="container d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between"
      >
        <div class="d-flex align-items-center mr-md-4">
          <div class="section__icon">
            <span class="btn btn-primary btn-icon-only">
              <i class="icon icon-description"></i>
            </span>
          </div>

          <h3 class="section__title cards__title" v-t="'events.latest-events'">
            Events
          </h3>
        </div>

        <div class="ml-md-auto flex-shrink-0 d-none d-md-block">
          <a
            :href="$t('events.all-events-button-url')"
            class="btn btn-sm btn-empty btn-empty-primary"
          >
            <span v-t="'events.all-events-button-label'">
              All events
            </span>
            <i class="icon icon-keyboard_arrow_right"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="section__body cards__body">
      <div class="container">
        <div class="row row--col-margin-small row--col-margin-md-medium">
          <div class="col-12 col-md-4" v-for="(event, i) in events" :key="i">
            <Event :event="event"> </Event>
          </div>
        </div>
      </div>
    </div>

    <div class="section__footer text-center d-block d-md-none mt-4">
      <a
        :href="$t('events.all-events-button-url')"
        class="btn btn-sm btn-empty btn-empty-primary"
      >
        <span v-t="'events.all-events-button-label'">
          All events
        </span>
        <i class="icon icon-keyboard_arrow_right"></i>
      </a>
    </div>
  </section>
</template>

<script>
import Event from "./Event.vue";

export default {
  props: {
    events: {
      type: Array
    },
    info: {
      type: Object
    }
  },
  components: {
    Event
  },
  created() {
    // console.log(this.events);
    // console.log(this.info.button[0].title);
  }
};
</script>

<style lang="css" scoped></style>
